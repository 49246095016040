import './Skills.scss';

const skills = [{
  title: 'Public Cloud - AWS',
  skills: [
    'CDK',
    'CloudFormation',
    'CloudWatch',
    'RDS',
    'S3',
    'Elasticache',
    'EC2',
    'ECS / EKS',
    'Route53',
    'ACM',
    'CloudFront',
  ],
}, {
  title: 'Technologies',
  skills: [
    'Cloudflare',
    'MSSQL',
    'Elasticsearch',
    'Redis',
    'MongoDB',
    'Couchbase',
    'HAProxy',
    'Nginx',
    'Docker',
    'Kubernetes',
    'SignalR',
  ],
}, {
  title: 'Languages',
  skills: [
    'Golang',
    '.NET (C# / VB)',
    'Typescript',
    'SQL / T-SQL',
    'GraphQL',
    'PowerShell',
    'Bash',
    'Python',
    'HTML',
    'CSS / LESS / SASS',
  ],
}, {
  title: 'Planning / Issue Tracking',
  skills: [
    'Jira',
    'Trello',
  ],
}, {
  title: 'Testing',
  skills: [
    'nUnit',
    'Selenium',
    'JMeter',
    'Gatling',
    'k6',
  ],
}, {
  title: 'Automation',
  skills: [
    'Ansible',
    'Puppet',
    'Gitlab CI/CD',
  ],
}, {
  title: 'Monitoring / Instrumentation',
  skills: [
    'Zabbix',
    'Grafana',
    'NewRelic',
    'Elastic APM',
    'VictoriaMetrics',
  ],
}, {
  title: 'On-Call',
  skills: [
    'OpsGenie',
    'VictorOps (Splunk On-Call)',
  ],
}, {
  title: 'Other',
  skills: [
    'Git',
    'SVN',
  ],
}]

export default function Skills() {
  return (
    <section id="skills">
      <div className="content">
        <h2>Skills and Abilities</h2>
        {skills.map((skillCategory, skillCategoryIdx) => 
          <div className="skill-category" key={skillCategoryIdx}>
            <h3>{skillCategory.title}</h3>
            <div  className="skills">
              {skillCategory.skills.map((skill, skillIdx) => 
                <div className="skill" key={skillIdx}>{skill}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
