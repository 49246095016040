import './App.scss';
import AboutMe from './components/AboutMe';
import Banner from './components/Banner';
import Contact from './components/Contact';
import Documents from './components/Documents';
import Footer from './components/Footer';
import Timeline from './components/Timeline';
import Menu from './components/Menu';
import Skills from './components/Skills';
import ReactTooltip from 'react-tooltip';

function App() {
  return (
    <div className="App">
      <Menu />
      <main>
        <Banner />
        <AboutMe />
        <Timeline />
        <Skills />
        <Documents />
        <Contact />
        <Footer />
      </main>
      <ReactTooltip />
    </div>
  );
}

export default App;
