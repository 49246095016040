import './AboutMe.scss';

export default function AboutMe() {
  return (
    <section id="aboutme">
      <div className="content">
        <h2>About Me</h2>
        <div className="bio">
          <p>
            In my free time I like to play video games, experiment with electronics, 3D print with FDM and Resin, and brew delicious beer.
          </p>
          <p>
            After spending most of my life in Victoria - both metropolitan and country - in 2019, I relocated to Brisbane; partly for 
            the weather, partly the beaches, but also to support my fiancée's career.
          </p>
          <p>
            Professionally, I'm an enthusiastic and eager backend developer with extensive knowledge of scalable and high-performance systems.
          </p>
        </div>
      </div>
    </section>
  );
}