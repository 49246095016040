import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Tech from './Tech';

export default function TimelineEvent({ logo, date, heading, content, tech, blurb, web, subItems }) {
  if (subItems && subItems.length > 0) {
    return ( 
      <>
      <VerticalTimelineElement
        className="vertical-timeline-element--work vertical-timeline-element--job-with-icon"
        icon={
          <div className="timeline-logo-container">
            <img className="timeline-logo" src={logo} alt={heading} />
          </div>
        }
        dateClassName="hidden"
        iconStyle={{ background: 'var(--timeline-icon-bg)' }}
        contentStyle={{ background: 'var(--timeline-item-bg)', color: 'var(--timeline-item-color)' }}
      >
        <h3 className="vertical-timeline-element-title">{heading}</h3>
        { blurb && 
          <p className="timeline-content">
            {blurb}
          </p>
        }
        { web && 
          <p className="timeline-content">
            <a href={web} rel="noreferrer" target="_blank">{web}</a>
          </p>
        }
        { 
          tech ? 
            <div className="job-tech">
              { tech.map((tech, techIdx) => 
                <Tech key={techIdx} tech={tech}/>
              )}
            </div>
          : <></>
        }
      </VerticalTimelineElement>
       {
          subItems.map((subItem, subItemIdx) =>            
            <VerticalTimelineElement
              key={subItemIdx}
              className="vertical-timeline-element--work vertical-timeline-element--job"
              date={subItem.date}
              iconStyle={{ background: 'var(--timeline-icon-bg)' }}
              contentStyle={{ background: 'var(--timeline-item-bg)', color: 'var(--timeline-item-color)' }}
            >
              <div className="timeline-subitem-header">
                <h4>{subItem.title}</h4>
              </div>
              <p className="timeline-subitem-content timeline-content">
                {subItem.content}
              </p>
              
              {subItem.list && subItem.list.length > 0 && 
                <ul className="timeline-project-container">
                  {
                    subItem.list.map((listItem, listItemIdx) => 
                      <li key={listItemIdx} className="timeline-project">
                        <div className="strong">{listItem.prefix}</div>
                        {listItem.suffix}
                      </li>
                    )
                  }
                </ul>
              }
            </VerticalTimelineElement>
          ) 
        }
      </>
    )
  }

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work vertical-timeline-element--job-with-icon"
      icon={
        <div className="timeline-logo-container">
          <img className="timeline-logo" src={logo} alt={heading} />
        </div>
      }
      date={date}
      iconStyle={{ background: 'var(--timeline-icon-bg)' }}
      contentStyle={{ background: 'var(--timeline-item-bg)', color: 'var(--timeline-item-color)' }}
    >
      <h3 className="vertical-timeline-element-title">{heading}</h3>    
      <p className="timeline-content">
        {content}
      </p>
    </VerticalTimelineElement>
  );
}