import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import './Timeline.scss';
import TimelineEvent from './TimelineEvent'

import BairnsdaleSecondaryLogo from '../pix/bairnsdale-secondary.png';
import CompassLogo from '../pix/compass.svg';
import EntainLogo from '../pix/entain.png';
import MonashUniversityLogo from '../pix/monash.png';
import NabLogo from '../pix/nab.svg';
import SagseLogo from '../pix/sagse.png';
import SwinburneLogo from '../pix/swinburne.png';

const timelineItems = [{
  logo: BairnsdaleSecondaryLogo,
  date: '2009 - 2011',
  heading: 'Bairnsdale Secondary College',
  content: `
    Successfully completed VCE studies and graduated as Dux of the class of 2011.
  `,
}, {
  logo: MonashUniversityLogo,
  date: '2011',
  heading: 'Monash University',
  content: `
    Completed a University Enhancement Program in Information Technology alongside VCE studies. 
    Learned about low-level computer system architecture, memory management and programming in Java.
  `,
}, {
  logo: SagseLogo,
  date: 'Nov 2011 - Feb 2012',
  heading: 'Society for Australian-German Student Exchange',
  content: `
    After hosting a German student in the year prior, I was given the opportunity to live 
    with a host family in northern Germany for three months. My profiency in the German language increased 
    and I made lifelong friends along the way.
  `,
}, {
  logo: SwinburneLogo,
  date: '2012 - 2014',
  heading: 'Swinburne University of Technology',
  content: `
    In 2012, I began studying a Bachelor of Information Technology, under Swinburne's Industry Scholarship Program. 
    The degree involved 20-week placements with two of their partner businesses - 
    NAB and Compass Education (at that time, JDLF International).
  `,
}, {
  logo: NabLogo,
  heading: 'National Australia Bank',
  tech: ['Java', 'Selenium', 'Jenkins'],
  web: 'https://nab.com.au/',
  blurb: 'One of the big four banks in Australia.',
  subItems: [{
    date: 'Dec 2013 - Feb 2014',
    title: 'Software Engineer',
    content: `
      After a successful placement period, I was extended an opportunity to continue through the summer working on the NABConnect business banking application.
    `,
  }, {
    date: 'Jul 2013 - Nov 2013',
    title: 'Software Engineer (IBL Student)',
    content: `
      My first placement as part of the university degree, NAB was my introduction to the corporate world.
    `,
    list: [{
      prefix: 'Frontend Testing',
      suffix: `
        produced a comprehensive UI testing framework built on top of Selenium, utilising plain-text test scripts written in Gherkin (given, when, then statements)
      `,
    }, {
      prefix: 'Bug Fixes',
      suffix: `
      fixed functionality, UI and accessibility issues with cross-device compatible solutions; meeting the required WCAG 2.0 AA rating
      `,
    }]
  }]
}, {
  logo: CompassLogo,
  heading: 'Compass Education',
  tech: ['C#', 'MSSQL', 'MongoDB', 'Redis', 'Cloudflare', 'Gitlab'],
  web: 'https://compass.education/',
  blurb: 'School management SaaS provider, headquartered in Melbourne.',
  subItems: [{
    date: 'Apr 2019 - July 2021',
    title: 'Manager - Platform Operations',
    content: `
      My appointment in this role coincided with my move to Brisbane, and saw the company develop a new satellite office in the sunshine state. 
      The rise of COVID-19 forced our engineering teams into Working From Home until only recently - Feb 2021 - when our Brisbane office was reestablished.
      This period saw a significant rise in platform traffic, with many schools forced to provide remote learning, as was mandated by several Australian state government(s).
    `,
    list: [{
      prefix: 'Cloud Migration',
      suffix: `
        driving public cloud adoption to best achieve scaling needs required by the product; building thorough business cases, and assisting engineering teams in designing and deploying new features directly in the cloud
      `,
    }, {
      prefix: 'Remote Management',
      suffix: `
        managing teams of Site Reliability Engineers based in Melbourne to improve site health and performance
      `,
    }, {
      prefix: 'Capacity Planning',
      suffix: `
        analysis of usage trends, capacity planning, and identification of key application modules requiring optimisation
      `,
    }, {
      prefix: 'Incident Management',
      suffix: `
        day-to-day management of the platform, including change management, incident response, documentation, and on-call roster participation
      `,
    }],
  },  {
    date: 'May 2017 - Apr 2019',
    title: 'Continuous Improvement Manager',
    content: `
      Branching out a little, I stepped into the role of Continuous Improvement Manager, with the purpose of spreading my understanding of production and the platform more generally with the engineering teams, beginning to build a DevOps mindset within the company.
      With my finger still on the pulse of production, these years also saw the maturity in the company grow substantially, with a key focus on automation and stability.
    `,
    list: [{
      prefix: 'Developer Tooling',
      suffix: `
        enhanced internal tooling to improve development practices and simplify processes
      `,
    }, {
      prefix: 'DevOps Mindset',
      suffix: `
        enabled teams to better understand the impact of changes upon production through dashboards, alerting and internal presentations
      `,
    }, {
      prefix: 'Ansible and Automation',
      suffix: `
        developing and automating deployment of system desired state configuration, performance, and application tuning config
      `,
    }, {
      prefix: 'Platform Stability',
      suffix: `
        provided technical and architectural advice for new projects to achieve platform reliability goals and uptime SLAs
      `,
    }, {
      prefix: 'On-Premise Kubernetes',
      suffix: `
        successfully delivered an on-premise Kubernetes installation from the ground up to revolutionise the hosting and management of critical services
      `,
    }],
  }, {
    date: 'Nov 2015 - May 2017',
    title: 'Senior Software Engineer',
    content: `
      The next step up in my career, Senior Software Engineer saw me put on a more formal mentorship hat; my focus was on code quality and maintainability.
      This role also saw me take more formal responsibility for the health of the production platform.
    `,
    list: [{
      prefix: 'Mentorship and Coaching',
      suffix: `
        conducted detailed code reviews, provided relevant and constructive feedback; mentored junior engineers on software development principles, design patterns, and writing maintainable and well-tested code
      `,
    }, {
      prefix: 'Session State',
      suffix: `
        managed the successful conversion of ASP.net Session State storage from Couchbase to Redis, for performance and scalability reasons
      `,
    }, {
      prefix: 'Cache Tuning',
      suffix: `
        optimised the performance and throughput of the base caching frameworks used by the application
      `,
    }],
  }, {
    date: 'Jul 2014 - Nov 2015',
    title: 'Software Engineer',
    content: `
      Continuing on from my work during placement, I quickly gained a reputation internally for being able to quickly understand and troubleshoot complex algorithms.
    `,
    list: [{
      prefix: 'Attendance and Imports',
      suffix: `
        expanded upon (and resolved issues with) the prediction of student attendance and external data import processes
      `,
    }, {
      prefix: 'Facial Recognition',
      suffix: `
      developed with facial recognition tooling and devised layout algorithms for school-wide photo booklets and yearbooks
      `,
    }]
  }, {
    date: 'Feb 2014 - Jul 2014',
    title: 'Software Engineer (IBL Student)',
    content: `
      My second placement as part of the university degree, Compass was a glimpse into the startup world; an up-and-coming 
      technology company delivering a learning, assessment and administration SaaS platform for schools all the way from Kinder to Year 12.
    `,
    list: [{
      prefix: 'Bug Fixes',
      suffix: `
        identified, triaged, and resolved issues logged by clients
      `,
    }]
  }]
}, {
  logo: EntainLogo,
  heading: 'Entain Group Pty Ltd',
  tech: ['Golang', 'AWS', 'MySQL', 'Redis', 'Kubernetes', 'Cloudflare', 'Gitlab'],
  web: 'https://entaingroup.com.au/',
  blurb: 'Home of Ladbrokes and Neds.',
  subItems: [{
    date: 'July 2021 - current',
    title: 'Performance Engineer',
    content: `
      Joining Entain's Site Reliability team in mid 2021, saw me thrust into the world of microservices.
      Featuring hundreds of independently managed services and an unfamiliar primary backend language, this role featured a steep but rewarding learning curve.
    `,
    list: [{
      prefix: 'Combo multis',
      suffix: `
        modified the storage method of "Any X Win" type bets, to alleviate system load while ensuring ongoing compliance
      `,
    }, {
      prefix: 'Bet settlement',
      suffix: `
        improved the overall platform bet settlement performance by a factor of 2x, leading to an extremely successful 2021 Melbourne Cup Carnival period
      `,
    }, {
      prefix: 'Go module support',
      suffix: `
        cooridinated the conversion of all services from $GOPATH module resolution to Go's inbuilt module system
      `,
    }]
  }]
}];

timelineItems.reverse();

export default function Timeline() {
  return (
    <section id="journey">
      <div className="content">
        <h2>My Journey</h2>
        <VerticalTimeline>
          {timelineItems.map((item, idx) => 
            <TimelineEvent key={idx} {...item} /> 
          )}
        </VerticalTimeline>
      </div>
    </section>
  );
}