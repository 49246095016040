import './Footer.scss';

const currentYear = new Date().getFullYear();
const copyrightYear = currentYear.toString();

export default function Footer() {
  return (
    <section id="footer">
      <div className="content">
        This website is built using React, hosted in AWS using an S3 bucket and Cloudflare.
        <p className="copyright">&copy; Copyright {copyrightYear}, Callum Adams</p>
      </div>
    </section>
  );
}