import './Menu.scss';
import React, { useState } from 'react';

export default function Menu() {
  const [isExpanded, setExpanded] = useState(false);

  const toggleMenu = () => {
    setExpanded(!isExpanded);
  };

  const menuItemClicked = () => {
    setExpanded(false);
  };

  return (
    <div className="menu-container">
      <nav id="menu" className={(isExpanded ? ' expanded' : '')}>
        <ul className="navbar-nav">
          <li className="logo">
            <a href="#banner" className="nav-link" onClick={menuItemClicked}>
              <div className="logo-text">Callum Adams</div>
            </a>
          </li>
          <li className="nav-expand">
            <span href="#" className="nav-link" onClick={toggleMenu}>
              <div className="nav-hamburger">
                <div className="nav-hamburger-line"></div>
              </div>
            </span>
          </li>
          <li className="nav-item">
            <a href="#aboutme" className="nav-link" onClick={menuItemClicked}>
              <span className="link-text">About Me</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#journey" className="nav-link" onClick={menuItemClicked}>
              <span className="link-text">My Journey</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#skills" className="nav-link" onClick={menuItemClicked}>
              <span className="link-text">Skills</span>
            </a>
          </li>
          <li className="nav-item">
            <a href="#documents" className="nav-link" onClick={menuItemClicked}>
              <span className="link-text">Documents</span>
            </a>
          </li>
          <li className="nav-item nav-item-contact">
            <a href="mailto:hi@cada.ms">
              <svg width="24" height="24" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1376 128q119 0 203.5 84.5t84.5 203.5v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960zm32 1056v-436q-31 35-64 55-34 22-132.5 85t-151.5 99q-98 69-164 69t-164-69q-47-32-142-92.5t-142-92.5q-12-8-33-27t-31-27v436q0 40 28 68t68 28h832q40 0 68-28t28-68zm0-573q0-41-27.5-70t-68.5-29h-832q-40 0-68 28t-28 68q0 37 30.5 76.5t67.5 64.5q47 32 137.5 89t129.5 83q3 2 17 11.5t21 14 21 13 23.5 13 21.5 9.5 22.5 7.5 20.5 2.5 20.5-2.5 22.5-7.5 21.5-9.5 23.5-13 21-13 21-14 17-11.5l267-174q35-23 66.5-62.5t31.5-73.5z" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/in/cadams93/" rel="noreferrer" target="_blank">
              <svg width="24" height="24" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M365 1414h231v-694h-231v694zm246-908q-1-52-36-86t-93-34-94.5 34-36.5 86q0 51 35.5 85.5t92.5 34.5h1q59 0 95-34.5t36-85.5zm585 908h231v-398q0-154-73-233t-193-79q-136 0-209 117h2v-101h-231q3 66 0 694h231v-388q0-38 7-56 15-35 45-59.5t74-24.5q116 0 116 157v371zm468-998v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z" />
              </svg>
            </a>
            <a href="https://github.com/cadams93" rel="noreferrer" target="_blank">
              <svg width="24" height="24" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M647 1200q4-6-3-13-9-7-14-2-4 6 3 13 9 7 14 2zm-28-41q-5-7-12-4-6 4 0 12 7 8 12 5 6-4 0-13zm-41-40q2-4-5-8-7-2-8 2-3 5 4 8 8 2 9-2zm21 23q2-1 1.5-4.5t-3.5-5.5q-6-7-10-3t1 11q6 6 11 2zm86 75q2-7-9-11-9-3-13 4-2 7 9 11 9 3 13-4zm42 3q0-8-12-8-10 0-10 8t11 8 11-8zm39-7q-2-7-13-5t-9 9q2 8 12 6t10-10zm642-317q0-212-150-362t-362-150-362 150-150 362q0 167 98 300.5t252 185.5q18 3 26.5-5t8.5-20q0-52-1-95-6 1-15.5 2.5t-35.5 2-48-4-43.5-20-29.5-41.5q-23-59-57-74-2-1-4.5-3.5l-8-8-7-9.5 4-7.5 19.5-3.5q6 0 15 2t30 15.5 33 35.5q16 28 37.5 42t43.5 14 38-3.5 30-9.5q7-47 33-69-49-6-86-18.5t-73-39-55.5-76-19.5-119.5q0-79 53-137-24-62 5-136 19-6 54.5 7.5t60.5 29.5l26 16q58-17 128-17t128 17q11-7 28.5-18t55.5-26 57-9q29 74 5 136 53 58 53 137 0 57-14 100.5t-35.5 70-53.5 44.5-62.5 26-68.5 12q35 31 35 95 0 40-.5 89t-.5 51q0 12 8.5 20t26.5 5q154-52 252-185.5t98-300.5zm256-480v960q0 119-84.5 203.5t-203.5 84.5h-960q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z" />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}