import './Banner.scss';
import logo from '../pix/profile-pic.png'

export default function Banner() {
  return (
    <section id="banner">
      <div className="banner-image">
        <img alt="Profile" src={logo}/>
      </div>
      <div className="banner-text">
        Hi, my name is
        <br />
        <span className="banner-name">Callum Adams</span>
      </div>
    </section>
  );
};